import Deck, { VERSION } from './reveal.js'
import * as math from './../plugin/math/katex.js'
import Typewriter from 'typewriter-effect/dist/core';
import Vivus from 'vivus';
import { autoSlide, loop } from './override.js';

/**
 * Expose the Reveal class to the window. To create a
 * new instance:
 * let deck = new Reveal( document.querySelector( '.reveal' ), {
 *   controls: false
 * } );
 * deck.initialize().then(() => {
 *   // reveal.js is ready
 * });
 */
let Reveal = Deck;


/**
 * The below is a thin shell that mimics the pre 4.0
 * reveal.js API and ensures backwards compatibility.
 * This API only allows for one Reveal instance per
 * page, whereas the new API above lets you run many
 * presentations on the same page.
 *
 * Reveal.initialize( { controls: false } ).then(() => {
 *   // reveal.js is ready
 * });
 */

let enqueuedAPICalls = [];

Reveal.initialize = options => {

	// Create our singleton reveal.js instance
	Object.assign(Reveal, new Deck(document.querySelector('.reveal'), options));

	// Invoke any enqueued API calls
	enqueuedAPICalls.map(method => method(Reveal));

	return Reveal.initialize({

		// Math plugin
		plugins: [
			math.KaTeX
		]
	});

}

/**
 * The pre 4.0 API let you add event listener before
 * initializing. We maintain the same behavior by
 * queuing up premature API calls and invoking all
 * of them when Reveal.initialize is called.
 */
['configure', 'on', 'off', 'addEventListener', 'removeEventListener', 'registerPlugin'].forEach(method => {
	Reveal[method] = (...args) => {
		enqueuedAPICalls.push(deck => deck[method].call(null, ...args));
	}
});

Reveal.isReady = () => false;

Reveal.VERSION = VERSION;

Reveal.initialize({
	autoSlide: autoSlide,
	loop: loop,
	navigationMode: 'linear',
	controlsTutorial: false
});

var animateCnn = new Vivus('cnn-model', {
	start: 'manual',
	duration: 200,
	type: 'sync',
	animTimingFunction: Vivus.EASE
});

var animateFwpModel = new Vivus('fwp-model', {
	start: 'manual',
	duration: 150,
	animTimingFunction: Vivus.EASE
});

var animateTransformerIntro = new Vivus('transformer-intro-model', {
	start: 'manual',
	duration: 400,
	animTimingFunction: Vivus.EASE
});

var animateEncoder = new Vivus('encoder-model', {
	start: 'manual',
	duration: 200,
	animTimingFunction: Vivus.EASE
});

var animateSelfAttn = new Vivus('self-attn-model', {
	start: 'manual',
	duration: 200,
	animTimingFunction: Vivus.EASE
});

var animateBert = new Vivus('bert-model', {
	start: 'manual',
	duration: 200,
	animTimingFunction: Vivus.EASE
});

var animateGpt = new Vivus('gpt-model', {
	start: 'manual',
	duration: 200,
	animTimingFunction: Vivus.EASE
});

var animateChainingDiag = new Vivus('chaining-diag-model', {
	start: 'manual',
	duration: 200,
	animTimingFunction: Vivus.EASE
});

var historyTyped = false;
var cnnAnimated = false;
var fwpAnimated = false;
var transformerIntroAnimated = false;
var encoderAnimated = false;
var selfAttnAnimated = false;
var bertAnimated = false;
var gptAnimated = false;
var chainingDiagAnimated = false;
Reveal.on('slidetransitionend', evt => {
	console.log(evt.currentSlide.id);

	if (evt.currentSlide.id === 'history' && !historyTyped) {
		historyTyped = true;
		new Typewriter('#history-type', {
			autoStart: false,
		})
			.changeDelay(60)
			.typeString('Kra')
			.changeDelay(80)
			.typeString('tak')
			.changeDelay(110)
			.typeString(' ')
			.pauseFor(300)
			.typeString('isto')
			.changeDelay(70)
			.typeString('rijatv')
			.pauseFor(200)
			.deleteChars(1)
			.start();
		return;
	}

	if (evt.currentSlide.id === "cnn" && !cnnAnimated) {
		cnnAnimated = true;
		animateCnn.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.currentSlide.id === 'fwp' && !fwpAnimated) {
		fwpAnimated = true;
		animateFwpModel.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.currentSlide.id === 'transformer-intro' && !transformerIntroAnimated) {
		transformerIntroAnimated = true;
		animateTransformerIntro.play(1, () => {
			Reveal.nextFragment();
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.currentSlide.id === 'encoder' && !encoderAnimated) {
		encoderAnimated = true;
		animateEncoder.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.currentSlide.id === 'self-attn' && !selfAttnAnimated) {
		selfAttnAnimated = true;
		animateSelfAttn.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.currentSlide.id === 'bert' && !bertAnimated) {
		bertAnimated = true;
		animateBert.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.currentSlide.id === 'gpt' && !gptAnimated) {
		gptAnimated = true;
		animateGpt.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.currentSlide.id === 'chaining-diag' && !chainingDiagAnimated) {
		chainingDiagAnimated = true;
		animateChainingDiag.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}
});

var animateMathModel = new Vivus('math-model', {
	start: 'manual',
	duration: 200,
	type: 'oneByOne',
	animTimingFunction: Vivus.EASE
});

var animateSdpa = new Vivus('sdpa-model', {
	start: 'manual',
	duration: 200,
	animTimingFunction: Vivus.EASE
});

var animateMha = new Vivus('mha-model', {
	start: 'manual',
	duration: 200,
	animTimingFunction: Vivus.EASE
});

var animateMlm = new Vivus('mlm-model', {
	start: 'manual',
	duration: 200,
	animTimingFunction: Vivus.EASE
});

var fmsSplit = false;
Reveal.on('fragmentshown', evt => {
	console.log("fragment: " + evt.fragment.id);

	if (evt.fragment.id === 'math-model-trigger') {
		animateMathModel.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.fragment.id === 'sdpa') {
		animateSdpa.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.fragment.id === 'mha') {
		animateMha.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.fragment.id === 'mlm') {
		animateMlm.play(1, () => {
			Reveal.nextFragment();
		});
		return;
	}

	if (evt.fragment.id === 'fms-trigger') {
		if (!fmsSplit) {
			fmsSplit = true;
			var elem = document.querySelector('#fms');
			var text = elem.textContent.trim();
			var words = text.split(/\s+/);
			elem.innerHTML = words.map(function (word) {
				return '<span>' + word + '</span>';
			}).join(' ');
		}

		function applyHighlight() {
			var spans = document.querySelectorAll('#fms span');
			var randomIndex = Math.floor(Math.random() * spans.length);
			var randomSpan = spans[randomIndex];

			// Add highlight class
			randomSpan.classList.add('fm-highlight');
			var timeOut = Math.floor(Math.random() * 500) + 250;

			setTimeout(function () {
				// Remove highlight class
				randomSpan.classList.remove('fm-highlight');
				// Call applyHighlight recursively after 0.5s
				setTimeout(applyHighlight, timeOut);
			}, timeOut);
		}

		for (var i = 0; i < 5; i++)
			applyHighlight();
		return;
	}

	if (evt.fragment.id === 'concl') {
		new Typewriter('#concl-type', {
			autoStart: false,
		})
			.changeDelay(60)
			.typeString('Napi')
			.changeDelay(80)
			.typeString('ši')
			.changeDelay(110)
			.typeString(' ')
			.changeDelay(60)
			.typeString('za')
			.changeDelay(90)
			.typeString('klju')
			.changeDelay(50)
			.typeString('čak')
			.changeDelay(150)
			.typeString(' rada:')
			.start();
		new Typewriter('#concl-robo-type', {
			autoStart: false,
			cursor: ''
		})
			.pauseFor(5000)
			.callFunction(() => {
				document.querySelector('#concl-type').classList.add('end-inputer');
			}, null)
			.changeDelay(5)
			.typeString('Duboko učenje uživa treći ')
			.pauseFor(200)
			.typeString('talas revolucije')
			.pauseFor(100)
			.typeString(', a transformer je ')
			.pauseFor(250)
			.typeString('jedan od najznačajnijih ')
			.pauseFor(50)
			.typeString('modela, koji će')
			.pauseFor(300)
			.typeString(' omogućiti paralelizaciju i')
			.pauseFor(200)
			.typeString(' efektniji trening')
			.pauseFor(100)
			.typeString('. Ovo će dovesti do ')
			.pauseFor(270)
			.typeString('široke komercijalizacije i upotrebe')
			.pauseFor(200)
			.typeString(' te revolucionarne arhitekture.')
			.start();
		return;
	}
});

export default Reveal;